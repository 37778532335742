import '../scss/index.scss';

const AOS = require('aos');

const calculateViewPortHeight = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

calculateViewPortHeight();

// We listen to the resize event
window.addEventListener('resize', () => {
  if (window.innerWidth >= 768) {
    calculateViewPortHeight();
  }
});

AOS.init();

$('video').each(function() {
  const video$ = $(this);
  const video = video$[0];

  video.addEventListener('loadedmetadata', function() {
    const width = this.videoWidth;
    const height = this.videoHeight;

    const ratio = height / width;

    video$.parent().css({
      paddingBottom: `${ratio * 100}%`,
    });
  });
});

const animateHeader = () => {
  const scrollTop = window.scrollY;

  const headerHeight = $('header').outerHeight();

  const headerOffset = headerHeight / 30;

  const headerEndOffset = Math.round(headerHeight * 0.1);

  const maxBorderRadius = 20;

  const maxPadding = 10;

  if (scrollTop > headerOffset) {
    const diff = scrollTop - headerOffset;

    const percentage = Math.round((diff / headerEndOffset) * 50);

    if (percentage > 100) {
      $('header .background-wrapper').css({
        borderRadius: maxBorderRadius,
        top: maxPadding,
        left: maxPadding,
        right: maxPadding,
      });

      return;
    }

    const currentBorderRadius = Math.round(
      (maxBorderRadius * percentage) / 100
    );

    const currentPadding = Math.round((maxPadding * percentage) / 100);

    $('header .background-wrapper').css({
      borderRadius: `${currentBorderRadius}px`,
      top: `${currentPadding}px`,
      left: `${currentPadding}px`,
      right: `${currentPadding}px`,
    });
  } else {
    $('header .background-wrapper').css({
      borderRadius: 0,
      top: 0,
      left: 0,
      right: 0,
    });
  }
};

animateHeader();

$(document).on('scroll', () => {
  animateHeader();
});
